import { log, error } from '@/services/Log'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

export const UseRedirectLegacyIoSAppsWatchdog: React.FC = () => {
  const router = useRouter()
  useEffect(() => {
    try {
      const agentsToRedirectPattern = /(OkliOSappNEW|OkliOS)/i
      const redirectPath = '/ios-app-redirect'
      const path = typeof window !== 'undefined' ? window.location.pathname : ''
      if (redirectPath === path || typeof navigator !== 'object') {
        return
      }
      const navigatorProps = [
        navigator?.appCodeName || '',
        navigator?.appName || '',
        navigator?.appVersion || '',
        navigator?.platform || '',
        navigator?.userAgent || '',
      ]
      const hasPropWithAppName = !!navigatorProps.find((v) => agentsToRedirectPattern.test(v))
      if (router && hasPropWithAppName) {
        log(`Redirecting user agent to IOS app redirect: navigator.userAgent: ${navigator.userAgent}`)
        router.push(redirectPath).catch(error)
      }
    } catch (err) {
      error('Failed to verify if client is a legacy IOS app user')
    }
  }, [router])
  return null
}
export default UseRedirectLegacyIoSAppsWatchdog
